import React , { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { MobilePackageDetailAccordian, PackageDetailCard, PackageStrip, SelectMultiplePackageCard, toast } from '../../components';
import { Axios } from '../../hooks';
import { TopSection } from '../../layout';
import { PopupModal,ConsolidatePopup } from '../../Modals';
import { refreshTopSectionData } from '../../store/topSectionData';
import './dashboard.css';
import { useNavigate } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { setFlagNotifications } from '../../store/userDetailsSlice';

const DashboardPage = () => {
  const { packages } = useSelector( state => state.topSectionData );
  const { userDetails, settings, warehouses ,flagNotifications} = useSelector( state => state.userData );
  const { marketingData } = useSelector( state => state.warehouseDetails );
  const { band } = marketingData;
  const [ selectedPackage , setSelectedPackage ] = useState({});
  const [ allPackages , setPackages ] = useState([]);
  const [ bandIndex , setBandIndex ] = useState(0);
  const [ selectMultiplePackage , setSelectMultiplePackage ] = useState(false);
  const [ packagesToCombined , setPackagesToCombined ] = useState([]);
  const [ mulitiplePackages , setmulitiplePAckages ] = useState([]);
  const [ separationLoading , setSeparationLoading ] = useState(false);
  const [ alreadySelectedCustomization , setAlreadySelectedCustomization ] = useState([]);
  const dispatch = useDispatch();
  const [ customizations , setCustomizations ] = useState([]);
  const [ allCustomizations , setAllCustomizations ] = useState([]);
  const [consolidatePopup, setConsolidetePopup] = useState(false);
  // const [ loading, setLoading ] = useState(false);
  // const [ openSummaryModal , setOpenSummaryModal ] = useState(false);
  // const [ openQuestionnaireModal , setOpenQuestionnaireModal ] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    hotjar.initialize(3576088, 6);
    dispatch(setFlagNotifications(flagNotifications?false:true))
  }, []);

  const unSelectPackage = () => {
    setPackages(prev => prev.map( prevPackage => ({...prevPackage , selected: false })));
  }

  const packageClicked = (packageData) => {
    if(selectMultiplePackage){
      if(packagesToCombined.filter( combinedPackage => combinedPackage.id === packageData.id).length > 0){
          setPackagesToCombined( prev => prev.filter( prevPackages => prevPackages.id !== packageData.id ));
          setPackages(prev => prev.map( prevPackage => prevPackage.id === packageData.id ? {...prevPackage , selected: false } : prevPackage ));
      }else{
          setPackages(prev => prev.map( prevPackage => selectMultiplePackage ? prevPackage.id === packageData.id ? { ...prevPackage , selected: true } : prevPackage : prevPackage.id === packageData.id ? { ...prevPackage , selected: true } : { ...prevPackage , selected: false }));
          setPackagesToCombined(prev => prev.length === 0 ? Object.keys(selectedPackage).length === 0 ? [...prev , packageData] : [selectedPackage , packageData ] : [...prev , packageData ]);
      }
    }else{
      setPackagesToCombined([]);
      setSelectedPackage(packageData);
      setPackages(prev => prev.map( prevPackage => prevPackage.id === packageData.id ? { ...prevPackage , selected: true } : { ...prevPackage , selected: false }));
    }
  }

  const unSelectMultiplePackages = () => {
    setPackages([]);
    setSelectedPackage({});
    setPackages( packages.map( prevPackage => ({ ...prevPackage , selected:false})));
    setPackagesToCombined([]);
    setSelectMultiplePackage(false);
    setmulitiplePAckages([])
    setConsolidetePopup(false)
  }

  const selectMultiplePackageFunc = () => {
    setmulitiplePAckages(packages)
    setPackages( packages.filter( data => (data.status !== 3 ) && ({ ...data , selected:false})));
    setPackagesToCombined([]);
    setSelectedPackage({});
    setSelectMultiplePackage(true);
  }
  
  useEffect(() => {
    setPackages(packages);
  },[packages]);

  useEffect(() => {
    let timer
    if( band.length > 1 ){
      clearInterval(timer);
      timer = setInterval(() => {
        setBandIndex( prev => band && prev === band.length - 1 ? 0 : prev = prev + 1 );
      },5000);
    }

    return () => clearInterval(timer)
  },[ band ]);

  const consolidationSeparation = async() => {
    setSeparationLoading(true);
    try{ 
        const res = await Axios.put(`/packages/extras/consolidationseparation/${selectedPackage.id}`, {}, { headers: { accessToken: localStorage.getItem('AccessToken')}});
        dispatch(refreshTopSectionData());
        toast( res.data.data , { type : 'success' });
        setSeparationLoading(false);
        setSelectedPackage({});
    }catch(err){
        setSeparationLoading(false);
        toast( err.response.data.error , { type : 'error' });
    }
  }

  useEffect(() => {
    (async() => {
        try{
          const allCustomizationsRes = await Axios.get(`/packages/extras`, { headers: { accessToken: localStorage.getItem('AccessToken')}});
          setCustomizations(allCustomizationsRes.data.data.filter( customization => customization.name !== 'Custom Photos Request' && customization.name !== 'Consolidation Separation' && customization.name !== 'Consolidation'));
          setAllCustomizations(allCustomizationsRes.data.data);
        }catch(err){
          toast( err.response.data.error , { type : 'error' });
        }
    })();
    },[ selectedPackage ] );

  useEffect(() => {
    if(selectedPackage.id){
      (async() => {
        try{ 
          const packageExtrasres = await Axios.get(`/packages/extras/${selectedPackage.id}` , { headers : { accessToken: localStorage.getItem('AccessToken')}});
          setAlreadySelectedCustomization(packageExtrasres.data.data[selectedPackage.id].map( extras => extras.extrasId));
          // setCustomizations( prev => prev.map( prevExtras => (packageExtrasres.data.data[selectedPackage.id].map( extras => extras.extrasId)).includes(prevExtras.id) ? {...prevExtras , selected: true } : { ...prevExtras , selected: false }));
          // setCheckCustomization(packageExtrasres.data.data[selectedPackage.id].map( extras => extras))
      }catch(err){
          toast( err.response.data.error , { type : 'error' });
        }
      })();
    }
   },[ selectedPackage ]);

   const checkConsolidationSeparation = () => {
    return allCustomizations.filter( customization => alreadySelectedCustomization.includes(customization.id))?.filter( element => element.name === 'Consolidation Separation' )?.length > 0 ? false : true
   }

   const selectMobilePackageFunction = (pkg) => {
      setSelectedPackage(pkg);
   }

   const setConsolidate=()=>{
    for(let i=0; i<packagesToCombined.length; i++){
      if(packagesToCombined[i].status<=2){
        toast("Please fill out the questionnaire!",{ type : 'info' })
        return
      }else if(packagesToCombined[i].status>9){
          toast(`The ${packagesToCombined[i].id} hold or return package does not allow combining`,{ type : 'info' })
          return
        }
        else if(packagesToCombined[i].consolidate){
          toast("The consolidation package does not allow combining",{ type : 'info' })
          return
        }
    }
    setConsolidetePopup(true)
  }

   const combinedPackage = async() => {
    try{
      for(let i=0; i<packagesToCombined.length; i++){
        if(packagesToCombined[i].status<=2){
          toast("Please fill out the questionnaire!",{ type : 'info' })
          return
        }else if(packagesToCombined[i].status>9){
          toast("The hold or return package does not allow combining",{ type : 'info' })
          return
        }else if(packagesToCombined[i].consolidate){
          toast("The consolidation package does not allow combining",{ type : 'info' })
          return
        }
      }
        await Axios.post('packages/consolidation' , { consolidatedItems: packagesToCombined.map( packageToCombine => packageToCombine.id)} , { headers: { accessToken: localStorage.getItem('AccessToken')}});
        unSelectMultiplePackages();
        toast( 'Consolidation Successfully!' , { type : 'success' });
        dispatch(refreshTopSectionData());
    }catch(err){
        toast( err.response.data.data , { type : 'error' });
    }
}

const forwordPackage = () => {
    if( userDetails.verified === 1){
      for(let i=0; packagesToCombined.length; i++){
        if(packagesToCombined[i].status<=2){
          toast("Please fill out the questionnaire!",{ type : 'info' })
          return
        }else if(packagesToCombined[i].status>9){
          toast(`The ${packagesToCombined[i].id} hold or return package does not allow to forward`,{ type : 'info' })
          return
        }
      }
      navigate('/dashboard/checkout-shipping-calculator' , { state : { packagesToCheckout : packagesToCombined , isMultiplePakage: true } } );
    }else{
        toast( "Please verify your email!" , { type : 'info' });
        return
    }
}

const getCostOfCustomizationByName = (customizationName) => {
  return allCustomizations.filter(customization => customization.name === customizationName)?.[0]?.cost || ''
}

  return (
    <div className='dashboard-container'>
       <TopSection isDashboardPage={true} setSelectedPackage={setSelectedPackage} />  
       {warehouses
    ? <div style={{ display: 'flex' , justifyContent: 'center' , alignItems: 'center', height: '100vh' }}><Spinner size='lg' /></div>
    :<>{
        band && band.length !== 0 && band?.map( element => bandIndex === band.indexOf(element) && <div className='yellow-horizhontal-line' key={element.id}>{element.content }</div>)
       }
       {window.innerWidth <= 992
       ? <div className='mobile-dashboard-container'>
            { selectMultiplePackage
            ? (<div className='table-heading flex08 blue-button cursor' onClick={() => unSelectMultiplePackages()}> Cancel </div>)
            : (<div className='table-heading flex08 border-blue-button cursor' onClick={() => selectMultiplePackageFunc()}>Select Multiple</div>)
            }
            {
              packagesToCombined.length > 1 && 
              <div className='combined-package-details-buttons'>
                  <div className='combined-package-button cursor' onClick={() => setConsolidate()}>
                    <div className='forward-package-button-text'>Combining ${getCostOfCustomizationByName('Consolidation')} / Package</div>
                    <span className="material-symbols-outlined">chevron_right</span>
                  </div>
                  <div className='forward-package-button cursor' onClick={() => forwordPackage()}>
                    <div className='forward-package-button-text'>Forward Package</div>
                    <span className="material-symbols-outlined">chevron_right</span>
                  </div>
              </div>
            }
            <div className='mobile-dashboard-packages-container'>
              {
                allPackages.length > 0
                ? allPackages.map( packages => <div key={packages.id}><MobilePackageDetailAccordian packageData={packages} selectPackageFunction={selectMobilePackageFunction} allCustomizations={allCustomizations}  customizations={customizations} setCustomizations={setCustomizations} setCustomizationsunSelectPackage={unSelectPackage} selectedPackage={selectedPackage} consolidate={true} setSelectedPackage={setSelectedPackage} alreadySelectedCustomization={alreadySelectedCustomization} setAlreadySelectedCustomization={setAlreadySelectedCustomization} checkConsolidationSeparation={checkConsolidationSeparation} separationLoading={separationLoading} setSeparationLoading={setSeparationLoading} consolidationSeparation={consolidationSeparation} selectMultiplePackage={selectMultiplePackage} packageClicked={packageClicked} allPackages={allPackages} setPackages={setPackages} unSelectPackage={unSelectPackage} mulitiplePackages={mulitiplePackages} /></div>)
                : <div className='no-packages-container'>
                    <img src='/images/no-package.svg' alt='no-packages-img' className='dashboard-empty-packages-img' />
                    <h2 className='big-gray-bold-text'>Welcome back, {userDetails?.firstName}</h2> 
                    <div className='text-border-color'>You don't have any packages yet. <br/> To begin, start shopping.</div>                 
                  </div>
              }
            </div>
       </div>
       : <div className='packages-list-and-package-details'>
          <div className='packages-table'>
            <div className='dashboard-table'>
              <div className='table-headings'>
                <div className='table-heading flex05'>Status</div>
                <div className='table-heading flex05'>Package Id</div>
                <div className='table-heading'>Incoming TrackId</div>
                <div className='table-heading flex06'>Recieved on</div>
                <div className='table-heading'>Product Name</div>
                { selectMultiplePackage
                ? (<div className='table-heading flex08 blue-button cursor' onClick={() => unSelectMultiplePackages()}> Cancel </div>)
                : (<div className='table-heading flex08 border-blue-button cursor' onClick={() => selectMultiplePackageFunc()}>Select Multiple</div>)
                }
              </div>
              <div className='table-contents'>
                { allPackages.length > 0 
                ? allPackages.map( packages => <div key={packages.id}><PackageStrip setSelectedPackage={setSelectedPackage} unSelectPackage={unSelectPackage} data={packages} packageClicked={packageClicked} shipped={true} mulitiplePackages={mulitiplePackages}/></div>) 
                : <div className='no-packages-container'>
                    <img src='/images/no-package.svg' alt='no-packages-img' className='dashboard-empty-packages-img' />
                    <h2 className='big-gray-bold-text'>Welcome back, {userDetails?.firstName}</h2> 
                    <div className='text-border-color'>You don't have any packages yet. <br/> To begin, start shopping.</div>                 
                  </div>
                }
              </div>
            </div>
          </div>
          <div className='package-details'>
              {
                selectMultiplePackage && packagesToCombined.length > 1
                ? <SelectMultiplePackageCard packagesToCombined={packagesToCombined} unSelectMultiplePackages={unSelectMultiplePackages} setConsolidate={setConsolidate} getCostOfCustomizationByName={getCostOfCustomizationByName}/>
                : Object.keys(selectedPackage).length === 0 ? 
                (
                  <>
                    <img src='/images/noPackagesImage.svg' alt='no-packages-img' className='no-packages-img' />
                    <div>No Packages Have Selected yet</div>
                  </>
                )
                : selectedPackage.consolidate === 1 
                ? selectedPackage.status === 3 
                ? (
                  <div className='consolidation-package-card-container'>
                    <img src='/images/waitTill-img.svg' alt='wait24hours-img' className='wait24hours' />
                    <div className='secondary-bold-big-text'>Please Wait 24 Hours</div>
                    <div className='primary-bold-text'>Your customizations are being handled.</div>
                    <div className='gray-text'>We’ve sent your package customization <br /> to the warehouse. Check back shortly to <br /> see the updates.</div>
                    { checkConsolidationSeparation() ? <div className='forward-package-button primary-separation-button cursor' onClick={() => consolidationSeparation()}>
                        <div className={`forward-package-button-text ${ separationLoading && 'disabled' }`}> Cancel Consolidation {' '} { separationLoading && <Spinner size='sm' />}</div>
                        {/* <span className="material-symbols-outlined">chevron_right</span> */}
                    </div>:<div className='forward-package-button primary-separation-button cursor' onClick={() => consolidationSeparation()}>
                    <div className={`forward-package-button-text ${ separationLoading && 'disabled' }`}> Cancel Consolidation Separation
                    {' '} { separationLoading && <Spinner size='sm' />}</div></div>}
                    <div className='phone-icon-text'>
                      {/* <a href={`tel:+${settings?.supportPhoneNumber || ''}`}><img src='/images/phone-img.svg' alt='phone-icon' /></a> */}
                      <a href='/support' className='primary-bold-text'> Contact the Customer Support </a>
                    </div>
                  </div>
                )
                : 
                  <PackageDetailCard allCustomizations={allCustomizations} customizations={customizations} setCustomizations={setCustomizations} unSelectPackage={unSelectPackage} selectedPackage={selectedPackage} consolidate={true} setSelectedPackage={setSelectedPackage} alreadySelectedCustomization={alreadySelectedCustomization} setAlreadySelectedCustomization={setAlreadySelectedCustomization} allPackages={allPackages} setPackages={setPackages} />
                : <PackageDetailCard allCustomizations={allCustomizations} customizations={customizations} setCustomizations={setCustomizations} unSelectPackage={unSelectPackage} selectedPackage={selectedPackage} consolidate={false} setSelectedPackage={setSelectedPackage} alreadySelectedCustomization={alreadySelectedCustomization} setAlreadySelectedCustomization={setAlreadySelectedCustomization} allPackages={allPackages} setPackages={setPackages} />
              }
          </div>
          </div>
       }
       {/* <SummaryModal show={openSummaryModal} setShow={setOpenSummaryModal} selectedPackage={selectedPackage} /> */}
       {/* <QuestionnaireModal unSelectPackage={unSelectPackage} show={openQuestionnaireModal} setShow={setOpenQuestionnaireModal} setSelectedPackage={setSelectedPackage} selectedPackage={selectedPackage} /> */}
       <PopupModal  />
       <ConsolidatePopup show={consolidatePopup} setShow={setConsolidetePopup} combinedPackage={combinedPackage} unSelectMultiplePackages={unSelectMultiplePackages}/>
       </>}</div>
  )
}

export { DashboardPage }